<template>
  <div class="enter-popup">
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="beforClose"
      :width="width"
    >
      <AddInformation
        ref="modal"
        :title="title"
        :genre="genre"
        :objDialog="objDialog"
        @informationRegistrationIncident="informationRegistrationIncident"
      ></AddInformation>
    </el-dialog>
  </div>
</template>
<script>
import AddInformation from '@/components/addInformation/index';
export default {
  name: 'EnterPopup',
  props: {
    title: {
      type: String,
      default: '编辑报名信息'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    genre: {
      // 编辑 false 添加 true
      type: Boolean,
      default: false
    },
    objDialog: {
      type: Object,
      default: {}
    },
    width: {
      type: String,
      default: '550px'
    }
  },
  components: {
    AddInformation
  },
  methods: {
    beforClose() {
      this.$emit('beforClose', {});
    },
    informationRegistrationIncident(obj) {
      this.$emit('informationRegistrationIncident', obj);
    },
    loadingIncident() {
      this.$refs.modal.loadingIncident();
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.enter-popup {
  box-sizing: border-box;
  .title {
    text-align: center;
    .fontSize(@TEXT-SIZE-18);
    font-weight: bold;
    margin-bottom: 30px;
  }
  .popupBox {
    height: 60px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    .left {
      width: 100px;
      margin-right: 10px;
      text-align: right;
      .fontSize(@TEXT-SIZE-16);
      font-weight: 400;
    }
    .rig {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      .inputBox {
        flex: 1;
        height: 38px;
        overflow: hidden;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 35px;
          padding: 0 15px;
        }
      }
      .codeImgShow {
        height: 35px;
        width: 80px;
        padding-left: 10px;
        cursor: pointer;
        box-sizing: border-box;
      }
      .code {
        width: 100px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: @TEXT-BG-F4F4F4;
        color: @TEXT-COLOR-888888;
        .fontSize(@TEXT-SIZE-14);
        margin-left: 10px;
        cursor: pointer;
      }
      .site-bg {
        margin-right: 40px;
        box-sizing: border-box;
        padding-left: 30px;
        height: 20px;
        background: url("~@/assets/images/home/unselected.png") no-repeat 0px
          0px;
        line-height: 18px;
        cursor: pointer;
        &.active {
          background: url("~@/assets/images/home/pitch-on.png") no-repeat 0px
            0px;
        }
      }
      .selectBox(100px,38px,10px);
    }
    .rigWihtd {
      .selectBox(100%,38px,0px);
    }
  }
  .dialog-btn {
    margin: 39px auto 0 auto;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-FFFFFF;
    background: @--color-primary;
  }
  /deep/.el-dialog {
    border-radius: 10px;
  }
  /deep/.el-dialog__body {
    padding: 20px 60px 40px 60px;
    box-sizing: border-box;
    color: @TEXT-COLOR-363636;
    overflow: hidden;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close:hover {
    color: @--color-primary;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close {
    color: @TEXT-COLOR-363636;
  }
  /deep/.el-dialog__header {
    font-size: 20px;
    font-weight: bold;
  }
  /deep/.el-dialog__headerbtn {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>