<template>
  <div class="site">
    <div class="flex conet site-box">
      <div class="site-left">昵称：</div>
      <div class="site-right">{{ userInfo.name }}</div>
    </div>
    <div class="flex conet site-box">
      <div class="site-left">手机号：</div>
      <div class="site-right">
        <div class="flex conet">
          <div
            :class="[
              'flex',
              'conet',
              'input-bor',
              'i-bor',
              phoneinc ? 'active' : '',
            ]"
            style="paddingleft: 15px"
          >
            <span v-if="userInfo.phone">{{
              userInfo.phone | phoneFilter
            }}</span>
            <span v-else>{{ userPhone | phoneFilter }}</span>
          </div>
          <div class="binding" @click.stop="bindingPhoneIncident">绑定</div>
        </div>
      </div>
    </div>
    <div class="flex conet site-box">
      <div class="site-left">地区：</div>
      <div class="site-right">
        <div class="select-box">
          <el-select
            v-model="userInfo.province"
            placeholder="省"
            @change="provinceChange($event, 'province')"
          >
            <el-option
              style="color: #363636; font-weight: normal"
              v-for="item in province_list"
              :key="item.id"
              :label="item.regionName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="userInfo.city"
            placeholder="市"
            :disabled="disabledCity"
            @change="provinceChange($event, 'city')"
          >
            <el-option
              style="color: #363636; font-weight: normal"
              v-for="item in city_list"
              :key="item.id"
              :label="item.regionName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="userInfo.area"
            placeholder="区/县"
            :disabled="disabledCounty"
            @change="provinceChange($event, 'area')"
          >
            <el-option
              style="color: #363636; font-weight: normal"
              v-for="item in county_list"
              :key="item.id"
              :label="item.regionName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="flex conet site-box">
      <div class="site-left">工作单位：</div>
      <div class="site-right">
        <div class="select-boxs">
          <el-select
            v-model="userInfo.schoolName"
            :remote-method="remoteMethod"
            remote
            placeholder="请输入工作单位"
            filterable
            :disabled="disabledLocation"
            @change="provinceChange($event, 'school')"
            style="width: 500px"
          >
            <el-option
              style="color: #363636; font-weight: normal"
              v-for="item in location_list"
              :key="item.id"
              :label="item.schoolName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="flex conet site-box">
      <div class="site-left">学段：</div>
      <div class="site-right">
        <div class="select-box flex conet">
          <el-select v-model="userInfo.rank" placeholder="选择学段">
            <el-option
              style="color: #363636; font-weight: normal"
              v-for="item in learningSectionList"
              :key="item.dictLabel"
              :label="item.dictLabel"
              :value="item.dictLabel"
            >
            </el-option>
          </el-select>
          <div class="subject">学科：</div>
          <el-select v-model="userInfo.subject" placeholder="选择学科">
            <el-option
              style="color: #363636; font-weight: normal"
              v-for="item in courseTypeList"
              :key="item.dictLabel"
              :label="item.dictLabel"
              :value="item.dictLabel"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div
      class="site-btn flex conet j-c"
      @click="getPersonalCenterupdateMyPersonalDetails"
    >
      保存
    </div>
    <PopupPhone
      ref=""
      :dialogVisible="dialogVisible"
      :show="show"
      @beforClose="
        () => {
          (dialogVisible = false), (show = true);
        }
      "
      @informationRegistrationIncident="informationRegistrationIncident"
    >
    </PopupPhone>
  </div>
</template>
<script>
import Utils from '@/utils';
import PopupPhone from './popup-phone';
import {
  personalCenterMyPersonalDetails,
  locationProvince,
  locationCity,
  locationArea,
  areaSchool,
  dictDatamanyDictData,
  personalCenterupdateMyPersonalDetails,
  personalCenterSmsVerify,
  bindUserPhoneToWechat
} from '@/api/personal';
import { userInfo } from '@/api/api';
import store from '@/store';
import { mapGetters } from 'vuex';
export default {
  name: 'Site',
  components: {
    PopupPhone
  },
  data() {
    return {
      province_list: [],
      city_list: [],
      disabledCity: true,
      county_list: [],
      disabledCounty: true,
      location_list: [],
      disabledLocation: true,
      phoneinc: false,
      dialogVisible: false,
      show: true,
      userInfo: {
        area: '', // 区
        city: '', // 市
        id: '', // 用户Id
        name: '', // 用户名
        phone: '', // 手机号
        province: '', // 省
        rank: '', // 学段
        schoolId: '', // 学校Id
        schoolName: '', // 学校名
        subject: '' // 所教科目
      },
      course_type: 'course_type', // 学科
      learning_section: 'learning_section', // 学段
      courseTypeList: [],
      learningSectionList: [],
      myJson: {},
      locationJson: {},
      schoolName: '',
      locationId: ''
    };
  },
  computed: {
    ...mapGetters(['userPhone'])
  },
  filters: {
    phoneFilter(val) {
      return val.substring(0, 3) + '****' + val.substring(7, val.length);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getPersonalCenterMyPersonalDetails();
      locationProvince({}).then((res) => {
        this.province_list = res.data;
      });
      dictDatamanyDictData([this.learning_section, this.course_type]).then(
        (res) => {
          if (res.code == 200) {
            const { course_type, learning_section } = res.data;
            this.courseTypeList = course_type.list;
            this.learningSectionList = learning_section.list;
          }
        }
      );
    },
    // 搜索远程
    remoteMethod(query) {
      if (query !== '') {
        this.schoolName = query;
        setTimeout(() => {
          this.getAreaSchool(this.locationId);
        }, 200);
      } else {
        this.schoolName = '';
        this.getAreaSchool(this.locationId);
      }
    },
    getPersonalCenterMyPersonalDetails() {
      const _this = this;
      personalCenterMyPersonalDetails({}).then((res) => {
        if (res.code == 200) {
          _this.userInfo.name = res.data.name || '';
          _this.userInfo.phone = res.data.phone || '';
          _this.userInfo.province = res.data.province || '';
          _this.userInfo.rank = res.data.rank || '';
          _this.userInfo.schoolId = res.data.schoolId || '';
          _this.userInfo.schoolName = res.data.schoolName || '';
          _this.userInfo.subject = res.data.subject || '';
          _this.userInfo.id = res.data.id;
          _this.userInfo.area = res.data.area;
          _this.userInfo.city = res.data.city;
        }
      });
    },
    /**
     * 城市 学校 学段 选择
     * @method 方法
     * */
    provinceChange(e, method) {
      switch (method) {
        case 'province': // 省选择
          this.disabledCity = true;
          this.disabledCounty = true;
          this.$set(this.userInfo, 'schoolId', '');
          this.$set(this.userInfo, 'city', '');
          this.$set(this.userInfo, 'area', '');
          this.$set(this.userInfo, 'schoolName', '');
          locationCity({
            provinceId: e
          }).then((res) => {
            if (res.code == 200) {
              this.city_list = res.data;
              this.disabledCity = false;
            }
          });
          break;
        case 'city': // 市
          this.disabledCounty = true;
          this.userInfo.area = '';
          this.userInfo.schoolName = '';
          locationArea({ cityId: e }).then((res) => {
            if (res.code == 200) {
              this.county_list = res.data;
              this.disabledCounty = false;
            }
          });
          break;
        case 'area':
          this.disabledLocation = true;
          this.userInfo.schoolName = '';
          this.locationId = e;
          this.getAreaSchool(this.locationId);
          break;
        case 'school':
          this.userInfo.schoolId = e;
          break;
        default:
          break;
      }
    },
    // 获取学校信息
    getAreaSchool(locationId) {
      areaSchool({
        locationId: locationId,
        schoolName: this.schoolName
      }).then((res) => {
        if (res.code == 200) {
          this.disabledLocation = false;
          this.location_list = res.data.list;
        }
      });
    },
    informationRegistrationIncident(e) {
      if (!this.userPhone) {
        const obj = {
          phone: e.phone,
          smsCode: e.code
        };
        bindUserPhoneToWechat(obj).then((res) => {
          if (res.code == 200) {
            userInfo({}).then((res) => {
              if (res.code == 200) {
                const { phone, userId, username, headImage, school } = res.data;
                store.commit('setUserwebImgUrl', headImage);
                store.commit('setUserwebUsername', username);
                store.commit('setUserwebUserId', userId);
                store.commit('setUserwebsSchool', school);
                store.commit('setUserwebPhone', phone);
                this.show = false;
                setTimeout(() => {
                  Utils.msgSuccess('绑定成功微信手机号成功');
                  this.dialogVisible = false;
                  this.show = true;
                  this.getPersonalCenterMyPersonalDetails();
                }, 1000);
              }
            });
            // setTimeout(() => {
            //   personalCenterSmsVerify(e).then(res => {
            //     if (res.code == 200) {
            //       this.show = false;
            //       setTimeout(() => {
            //         Utils.msgSuccess('绑定成功');
            //         this.dialogVisible = false;
            //         this.show = true;
            //         this.getPersonalCenterMyPersonalDetails();
            //       }, 1000)
            //     }
            //   })
            // }, 1000)
          }
        });
      } else {
        personalCenterSmsVerify(e).then((res) => {
          if (res.code == 200) {
            this.show = false;
            setTimeout(() => {
              Utils.msgSuccess('绑定成功');
              this.dialogVisible = false;
              this.show = true;
              this.getPersonalCenterMyPersonalDetails();
            }, 1000);
          }
        });
      }
    },
    bindingPhoneIncident() {
      this.dialogVisible = true;
    },
    getPersonalCenterupdateMyPersonalDetails() {
      if (!this.userPhone) {
        this.$phonePopup((res) => {});
        return;
      }
      if (this.userInfo.city == '') {
        Utils.msgWarning('请选择您所在的市区');
        return;
      }
      if (this.userInfo.area == '') {
        Utils.msgWarning('请选择您所在的区县');
        return;
      }
      if (this.userInfo.schoolName == '') {
        Utils.msgWarning('请选择您所在的工作单位');
        return;
      }
      personalCenterupdateMyPersonalDetails(this.userInfo).then((res) => {
        if (res.code == 200) {
          this.getPersonalCenterMyPersonalDetails();
          Utils.msgSuccess('保存成功');
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.site {
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 75px;
  .fontSize(@TEXT-SIZE-16);
  .site-box {
    height: 60px;
    .site-left {
      width: 36%;
      text-align: right;
    }
    .site-right {
      margin-left: 10px;
      color: @TEXT-COLOR-888888;
      .site-bg {
        margin-right: 40px;
        box-sizing: border-box;
        padding-left: 30px;
        height: 20px;
        background: url("~@/assets/images/home/unselected.png") no-repeat 0px
          0px;
        line-height: 18px;
        cursor: pointer;
        &.active {
          background: url("~@/assets/images/home/pitch-on.png") no-repeat 0px
            0px;
        }
      }
      .input-bor {
        width: 260px;
        height: 38px;
        overflow: hidden;
        margin-right: 20px;
        padding-left: 15px;
        padding-right: 15px;
        input {
          width: 100%;
          height: 36px;
          padding-left: 15px;
          padding-right: 15px;
          color: @TEXT-COLOR-888888;
          .fontSize(@TEXT-SIZE-16);
          line-height: 36px;
        }
      }
      .binding {
        color: @TEXT-COLOR-FF5102;
        font-weight: 400;
        cursor: pointer;
      }
      .select-box {
        box-sizing: border-box;
        .selectBox(120px,36px,20px);
      }
      .select-boxs {
        .selectBox(400px,38px,0px);
      }
      .subject {
        color: @TEXT-COLOR-363636;
        margin-left: 12px;
        margin-right: 10px;
      }
    }
  }
  .site-btn {
    width: 180px;
    height: 40px;
    border-radius: 20px;
    background: @--color-primary;
    .fontSize(@TEXT-SIZE-20);
    color: @TEXT-COLOR-FFFFFF;
    cursor: pointer;
    margin: 60px auto 0 auto;
  }
}
</style>